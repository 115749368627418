<template>
  <VAsync :await="request">
    <template #default="{ pending, error }">
      <BLoading v-if="pending" :is-full-page="false" active />
      <BMessage
        v-else-if="error"
        type="is-danger"
        has-icon
        icon-size="is-small"
      >
        {{ $t('unexpected-error-info-message') }}
      </BMessage>
      <div v-else id="ontology-details-container">
        <VForm>
          <template #default="form">
            <table class="table is-fullwidth">
              <tbody>
                <tr>
                  <td colspan="2">
                    <strong>{{ $t('terms') }}</strong>
                    <div v-for="term in activeTerms" :key="term.id">
                      <span v-if="!fullOntology.isLocked" class="mr-5">
                        <IdxBtn
                          small
                          class="mr-1"
                          @click="confirmTermDeletion(term)"
                        >
                          <span class="icon is-small">
                            <i class="far fa-trash-alt" />
                          </span>
                        </IdxBtn>
                        <IdxBtn small @click="openTermModal(term)">
                          <span class="icon is-small">
                            <i class="fas fa-edit" />
                          </span>
                        </IdxBtn>
                      </span>
                      <span class="align-left gap-8 pr-4">
                        <CytomineTerm :term="term" />

                        <span v-if="currentUser.adminByNow" class="term-id">({{ term.id }})</span>
                      </span>
                    </div>
                    <div class="my-2 text-center">
                      <IdxBtn
                        v-if="!fullOntology.isLocked"
                        small
                        @click="openTermModal()"
                      >
                        {{ $t('add-term') }}
                      </IdxBtn>
                      <IdxBtn
                        v-if="!fullOntology.isLocked"
                        class="ml-2"
                        small
                        @click="openTermImportModal()"
                      >
                        {{ $t('import-terms') }}
                      </IdxBtn>
                    </div>
                    <template v-if="deletedTerms.length && showDeletedTerms">
                      <strong>{{ $t('deleted-terms') }}</strong>
                      <div
                        v-for="term in deletedTerms"
                        :key="term.id"
                        class="flex justify-between"
                      >
                        <div class="flex align-center gap-8 pr-4">
                          <CytomineTerm :term="term" />
                          <span v-if="currentUser.adminByNow" class="term-id"
                            >({{ term.id }})</span
                          >
                          <div>
                            <IdxBtn
                              v-if="!fullOntology.isLocked"
                              small
                              class="mr-2"
                              @click="restoreTerm(term)"
                            >
                              {{ $t('restore') }}
                            </IdxBtn>
                          </div>
                        </div>
                      </div>
                      <IdxBtn class="my-4" link @click="toggleDeletedTerms">
                        {{ $t('deleted-terms-hide') }}
                      </IdxBtn>
                    </template>
                    <template v-if="deletedTerms.length && !showDeletedTerms">
                      <IdxBtn class="my-4" link @click="toggleDeletedTerms">
                        {{ $t('deleted-terms-show') }}
                      </IdxBtn>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>{{ $t('studies') }}</strong>
                  </td>
                  <td>
                    <template v-if="projects.length">
                      <span
                        v-for="(project, index) in projects"
                        :key="project.id"
                      >
                        <RouterLink :to="`/project/${project.id}`">
                          {{ project.name }}
                        </RouterLink>
                        <span v-if="index < projects.length - 1">, </span>
                      </span>
                    </template>
                    <em v-else-if="projects.length > 0" class="has-text-grey">
                      {{ $t('project-used-in-no-access') }}
                    </em>
                    <em v-else class="has-text-grey">
                      {{ $t('project-not-used-in-any') }}
                    </em>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>{{ $t('creator') }}</strong>
                  </td>
                  <td>
                    {{ creatorFullname || $t('unknown') }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>{{ $t('multiple-term-fill-color') }}</strong>
                  </td>
                  <td>
                    <template v-if="!fullOntology.isLocked">
                      <sketch-picker
                        v-model="multiTermFillColor"
                        :preset-colors="[]"
                        :disable-alpha="false"
                      />
                    </template>
                    <template v-else>
                      <ColorPreview :color="fullOntology.multiTermFillColor" />
                      {{ fullOntology.multiTermFillColor }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>{{ $t('multiple-term-stroke-color') }}</strong>
                  </td>
                  <td>
                    <template v-if="!fullOntology.isLocked">
                      <sketch-picker
                        v-model="multiTermStrokeColor"
                        :preset-colors="[]"
                        :disable-alpha="false"
                      />
                    </template>
                    <template v-else>
                      <ColorPreview
                        :color="fullOntology.multiTermStrokeColor"
                      />
                      {{ fullOntology.multiTermStrokeColor }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>{{ $t('multiple-term-stroke-width') }}</strong>
                  </td>
                  <td>
                    <template v-if="!fullOntology.isLocked">
                      <IdxInput
                        v-model="multiTermStrokeWidth"
                        type="number"
                        name="multiTermStrokeWidth"
                        label=""
                        min="0"
                        max="20"
                      />
                    </template>
                    <template v-else>
                      <ColorPreview
                        :color="fullOntology.multiTermStrokeWidth"
                      />
                      {{ fullOntology.multiTermStrokeWidth }}
                    </template>
                  </td>
                </tr>
                <tr v-if="currentUser.adminByNow">
                  <td>
                    <strong>{{ $t('meta') }}</strong>
                  </td>
                  <td>
                    <template v-if="!fullOntology.isLocked">
                      <div class="flex gap-8">
                        <IdxInput
                          v-model="metaText"
                          label="Meta"
                          hide-label
                          type="textarea"
                          name="meta"
                          class="flex-grow"
                        />
                      </div>
                      ({{ $t('json-formats-only') }})
                    </template>
                    <template v-else>
                      {{ metaText }}
                    </template>
                  </td>
                </tr>
                <tr v-if="canEdit">
                  <td>
                    <strong>{{ $t('actions') }}</strong>
                  </td>
                  <td v-if="!fullOntology.isLocked">
                    <IdxBtn
                      class="mr-2"
                      :disabled="!form.valid"
                      @click="updateOntology"
                    >
                      {{ $t('save') }}
                    </IdxBtn>
                    <IdxBtn class="mr-2" @click="isRenameModalActive = true">
                      {{ $t('button-rename') }}
                    </IdxBtn>
                    <IdxBtn
                      :disabled="projects.length > 0"
                      :title="
                        projects.length
                          ? $t('cannot-delete-ontology-with-projects')
                          : ''
                      "
                      color="danger"
                      @click="confirmDeletion"
                    >
                      {{ $t('delete') }}
                    </IdxBtn>
                  </td>
                  <td v-else class="italic">
                    {{ $t('ontology-is-locked') }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </VForm>

        <RenameModal
          :title="$t('rename-ontology')"
          :current-name="ontology.name"
          :active.sync="isRenameModalActive"
          @rename="rename"
        />
        <TermImportModal
          :active.sync="showTermImportModal"
          :ontology="fullOntology"
          @finished="fetchOntology"
        />
      </div>
    </template>
  </VAsync>
</template>

<script>
import { Sketch } from 'vue-color';
import noteApi from '../../services/noteApi.js';
import RenameModal from '../utils/RenameModal.vue';
import { fullName } from '../../utils/user-utils.js';
import TermImportModal from './ImportOntologyTermModal.vue';
import CytomineTerm from '@/components/ontology/CytomineTerm.vue';
import ColorPreview from '@/components/utils/ColorPreview.vue';

/**
 * @typedef {{
 * id: number
 * name: string
 * user: number
 * terms: Array<any>
 * }} Ontology
 */

export default {
  name: 'OntologyDetails',
  components: {
    RenameModal,
    TermImportModal,
    CytomineTerm,
    ColorPreview,
    'sketch-picker': Sketch,
  },
  props: {
    /** @type {import('vue').PropOptions<{ id: number, name: string }>} */
    ontology: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      request: null,
      /** @type {Ontology} */
      fullOntology: null,
      projects: [],
      managedProjects: [],
      creatorFullname: null,
      showDeletedTerms: false,
      showTermImportModal: false,

      isRenameModalActive: false,
      metaInfo: null,
      metaText: '',
      multiTermFillColor: { hex8: '#fff' },
      multiTermStrokeColor: { hex8: '#000' },
      multiTermStrokeWidth: 2,

      presetColors: [
        '#F44E3B',
        '#FB9E00',
        '#FCDC00',
        '#68BC00',
        '#16A5A5',
        '#009CE0',
        '#7B10D8',
        '#F06292',
        '#000',
        '#777',
        '#FFF',
      ],
    };
  },
  computed: {
    /** @returns {object} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    canEdit() {
      const { currentUser, projects } = this;
      // Check if they are a guest
      if (currentUser.guestByNow) return false;
      // Check if they are an admin/superadmin
      if (currentUser.admin) return true;
      // Check if they are the ontology owner
      if (currentUser.id === this.fullOntology.user) return true;
      // Check if they have access to all projects in ontology
      if (this.nbProjects !== projects.length) return false;
      // Check if all projects are collaborative or user can manage all projects
      if (
        !projects.some((p) => p.isRestricted || p.isReadOnly) ||
        !projects.some(
          (p) => !this.managedProjects.find((mp) => mp.id === p.id)
        )
      ) {
        return true;
      }
      return false;
    },
    /** @returns {number} */
    nbProjects() {
      const fullOntology = this.fullOntology;
      if (!fullOntology) return 0;
      return this.projects.length;
    },
    activeTerms() {
      const fullOntology = this.fullOntology;
      if (!fullOntology) return [];
      return fullOntology.terms.filter((t) => !t.deleted);
    },
    deletedTerms() {
      const fullOntology = this.fullOntology;
      if (!fullOntology) return [];
      return fullOntology.terms.filter((t) => t.deleted);
    },
  },
  watch: {
    ontology: {
      handler() {
        this.fetchOntology();
      },
      immediate: true,
    },
    metaInfo() {
      if (this.metaInfo == 'null') {
        this.metaText = '';
      } else {
        this.metaText = this.metaInfo;
      }
    },
  },
  methods: {
    fetchOntology() {
      const ontologyId = this.ontology.id;
      this.request = async () => {
        try {
          const requests = [
            // Ontology details
            noteApi.get(
              `/napi/ontology/${ontologyId}?includeDeletedTerms=true`
            ),
            // Ontology projects current user has access to
            noteApi
              .get(`/api/ontology/${ontologyId}/project.json`)
              .then((r) => r.collection),
            // Current user's managed projects
            noteApi
              .get(`/api/user/${this.currentUser.id}/project/light.json`, {
                query: {
                  max: 0,
                  admin: true,
                },
              })
              .then((r) => r.collection),
          ];

          const [fullOntology, projects, managedProjects] = await Promise.all(
            requests
          );

          this.fullOntology = fullOntology;

          if (fullOntology) {
            this.multiTermFillColor = fullOntology.multiTermFillColor;
            this.multiTermStrokeColor = fullOntology.multiTermStrokeColor;
            this.multiTermStrokeWidth = fullOntology.multiTermStrokeWidth;
          }

          try {
            this.metaInfo = JSON.stringify(fullOntology.meta);
          } catch (error) {
            console.log(error);
          }
          // projects prop of ontology contains all projects, including those that the current user cannot see => need to refetch the collection
          this.projects = projects;
          this.managedProjects = managedProjects;

          const creator = await noteApi.get(
            `/api/user/${fullOntology.userId}.json`
          );
          this.creatorFullname = fullName(creator);
        } catch (error) {
          console.log(error);
        }
      };
    },
    isValidJson(input) {
      try {
        JSON.parse(input);
        return true;
      } catch (e) {
        return false;
      }
    },
    async updateOntology() {
      try {
        if (this.metaText && !this.isValidJson(this.metaText)) {
          alert(this.$t('invalid-json'));
          return;
        }
        const data = {
          meta: this.metaText,
          multiTermFillColor: this.multiTermFillColor.hex8
            ? this.multiTermFillColor.hex8
            : this.multiTermFillColor,
          multiTermStrokeColor: this.multiTermStrokeColor.hex8
            ? this.multiTermStrokeColor.hex8
            : this.multiTermStrokeColor,
          multiTermStrokeWidth: this.multiTermStrokeWidth,
        };
        const r = await noteApi.patch(`/napi/ontology/${this.ontology.id}`, {
          json: data,
        });
        console.log(r);
        this.$notify({
          type: 'success',
          text: this.$t('ontology-update-success'),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('ontology-update-error'),
        });
      }
    },

    async rename(newName) {
      try {
        await noteApi.patch(`/napi/ontology/${this.ontology.id}`, {
          json: {
            name: newName,
          },
        });
        this.fullOntology.name = newName;
        this.$notify({
          type: 'success',
          text: this.$t('ontology-rename-success', {
            name: newName,
          }),
        });
        this.$emit('rename', newName);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('ontology-rename-failed', { name: this.ontology.name }),
        });
      }
      this.isRenameModalActive = false;
    },

    openTermModal(term) {
      this.$buefy.modal.open({
        parent: this,
        component: () => import('./TermModal.vue'),
        props: {
          term: term,
          ontology: this.fullOntology,
        },
        events: {
          newTerm: () => {
            this.request = async () => {
              this.fullOntology = await noteApi.get(
                `/napi/ontology/${this.ontology.id}`
              );
            };
          },
          updateTerm: this.updateTerm,
        },
        hasModalCard: true,
      });
    },
    openTermImportModal() {
      this.showTermImportModal = true;
    },
    updateTerm(term) {
      const fullOntology = this.fullOntology;
      const index = fullOntology.terms.findIndex((item) => item.id === term.id);
      this.fullOntology.terms.splice(index, 1, term);
    },

    async confirmTermDeletion(term) {
      try {
        //  Check to see if term is being used in any annotations
        const response = await noteApi.get(
          `napi/annotation/term?termId=${term.id}`
        );
        let confirmDeleteMsg;
        if (response.countAffected > 0) {
          confirmDeleteMsg = this.$t('confirm-deletion-term-with-annots', {
            name: term.name,
          });
        } else {
          confirmDeleteMsg = this.$t('confirm-deletion-term', {
            name: term.name,
          });
        }
        this.$buefy.dialog.confirm({
          title: this.$t('confirm-deletion'),
          message: confirmDeleteMsg,
          type: 'is-danger',
          confirmText: this.$t('confirm'),
          cancelText: this.$t('cancel'),
          onConfirm: () => this.deleteTerm(term),
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-term-deletion'),
        });
      }
    },
    async deleteTerm(term) {
      try {
        const fullOntology = this.fullOntology;
        const index = fullOntology.terms.findIndex(
          (item) => item.id === term.id
        );

        // soft deletes ontology
        await noteApi.delete(
          `/napi/ontology/${this.ontology.id}/term/${term.id}`
        );

        this.fullOntology.terms[index].deleted = new Date();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-term-deletion'),
        });
      }
    },

    async restoreTerm(term) {
      try {
        const success = await noteApi.put(
          `/napi/ontology/${this.ontology.id}/term/${term.id}/enable`
        );
        if (success && !success.error) {
          term.deleted = null;
        }
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-term-restoration'),
        });
      }
    },

    confirmDeletion() {
      this.$buefy.dialog.confirm({
        title: this.$t('confirm-deletion'),
        message: this.$t('confirm-deletion-ontology', {
          name: this.ontology.name,
        }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.$emit('delete'),
      });
    },

    toggleDeletedTerms() {
      this.showDeletedTerms = !this.showDeletedTerms;
    },
  },
};
</script>
<style lang="scss">
#ontology-details-container {
  .term-id {
    font-size: 0.8rem;
    color: #7f8c8d;
  }
  .vc-sketch {
    width: 400px;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  }
  .vc-sketch-saturation-wrap {
    padding-bottom: 30%;
  }
  .vc-sketch-hue-wrap {
    flex-grow: 1;
  }
}
</style>
