<template>
  <CytomineModal :active="active" @close="$emit('update:active', false)">
    <BLoading :is-full-page="false" :active="loading" class="small" />
    <template v-if="!loading">
      <BSteps
        v-model="activeStep"
        class="flex flex-column h-full w-full align-center justify-center"
      >
        <!-- 1ST STEP -- ONTOLOGY -->
        <BStepItem
          :step="1"
          :label="$t('ontology')"
          class="flex flex-column justify-center h-full"
          style="width:400px; margin:0 auto;"
        >
          <BField :label="$t('import-from-ontology')" class="mb-5">
            <BSelect
              v-model="selectedOntologyId"
              expanded
              @input="onOntologyChanged"
            >
              <option
                v-for="ontology in ontologies"
                :key="ontology.id"
                :value="ontology.id"
              >
                {{ ontology.name }}
              </option>
            </BSelect>
          </BField>
        </BStepItem>

        <!-- 2ND STEP -- TERMS -->
        <BStepItem :step="2" :label="$t('terms')" class="pt-5">
          <div class="mt-5 pb-3">
            <table class="mt-5" style="width:700px; margin:0 auto;">
              <tr>
                <td class="pb-3">
                  <BCheckbox
                    :value="allTermsSelected"
                    :native-value="allTermsSelected"
                    @input="toggleAllTerms"
                  >
                    {{ $t('toggle-all') }}
                  </BCheckbox>
                </td>
                <td />
              </tr>
              <tr v-for="(termGroup, i) in groupedTerms" :key="i">
                <td v-for="term in termGroup" :key="term.id">
                  <BCheckbox
                    v-model="selectedTerms"
                    :value="term.id"
                    :native-value="term.id"
                  >
                    <span
                      class="inline-block p-2"
                      :style="{ background: term.color }"
                    />
                    {{ term.name }}
                  </BCheckbox>
                </td>
              </tr>
            </table>
          </div>
        </BStepItem>

        <!-- NAVIGATION BUTTONS -->
        <template #navigation="{previous, next}">
          <div class="flex w-full pr-5 justify-end">
            <span
              v-if="!isStepValid"
              class="mt-1 mr-4 red"
              style="font-size: 0.8rem; display: inline-block"
            >
              {{ getValidationMessage() }}
            </span>
            <IdxBtn
              class="mr-3"
              :disabled="previous.disabled || activeStep === 0"
              @click="previous.action"
            >
              {{ $t('previous') }}
            </IdxBtn>
            <IdxBtn
              type="submit"
              color="primary"
              :disabled="!isStepValid"
              @click="goNext(next.action)"
            >
              {{
                activeStep === steps.length - 1
                  ? $t('import-terms')
                  : $t('next')
              }}
            </IdxBtn>
          </div>
        </template>
      </BSteps>
    </template>
    <!-- stub to hide footer -->
    <template #footer>
      <span />
    </template>
  </CytomineModal>
</template>
<script>
import { OntologyCollection } from 'cytomine-client';
import noteApi from '../../services/noteApi.js';
import CytomineModal from '@/components/utils/CytomineModal.vue';
const getDefaultState = () => ({
  loading: true,
  selectedOntology: {},
  selectedOntologyId: '',
  terms: [],
  ontologies: [],
  selectedTerms: [],
  steps: ['Ontology', 'Terms'],
  activeStep: 0,
  termsSelection: 'all',
});
export default {
  name: 'ImportOntologyTermModal',
  components: {
    CytomineModal,
  },
  props: {
    active: Boolean,
    ontology: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return getDefaultState();
  },
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },

    isStepValid() {
      let isValid = false;
      switch (this.activeStep) {
        case 0:
          isValid = this.selectedOntologyId;
          break;
        case 1:
          isValid = this.selectedTerms.length;
          break;
        default:
      }
      return isValid;
    },

    // groups the terms into 2 columns for display purposes
    groupedTerms() {
      const groupSize = 2;
      const groupedTerms = [];
      let activeGroup = [];
      for (const term of this.terms) {
        activeGroup.push(term);
        if (activeGroup.length === groupSize) {
          groupedTerms.push(activeGroup);
          activeGroup = [];
        }
      }
      if (activeGroup.length > 0) {
        groupedTerms.push(activeGroup);
      }
      return groupedTerms;
    },

    allTermsSelected() {
      return (
        this.selectedTerms.length === this.terms.length &&
        this.selectedTerms.length > 0
      );
    },
  },
  watch: {
    async active() {
      if (this.active) {
        this.loading = true;

        // reset modal state & hide modal
        for (const key in getDefaultState()) {
          this[key] = getDefaultState()[key];
        }

        // get all ontologies
        const ontologyRequestProps = {
          light: true,
          sort: 'name',
          order: 'asc',
        };
        const ontologies = await OntologyCollection.fetchAll(
          ontologyRequestProps
        );

        if (ontologies?.length) {
          this.ontologies = ontologies._data.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        }

        this.loading = false;
      }
    },
  },
  methods: {
    goNext(nextStep) {
      if (this.activeStep === this.steps.length - 1) {
        nextStep();
        this.doSubmit();
      } else {
        nextStep();
      }
    },
    async onOntologyChanged() {
      this.loading = true;

      // ensure ontology is not locked and get terms
      const selectedOntology = await noteApi.get(
        `/napi/ontology/${this.selectedOntologyId}`
      );

      if (!selectedOntology || selectedOntology.terms.length === 0) {
        this.$notify({
          type: 'error',
          text: this.$t('ontology-has-no-terms'),
        });
        this.selectedOntologyId = '';
        this.loading = false;
        return;
      }

      this.terms = selectedOntology.terms.filter((term) => {
        return !this.ontology.terms.find((t) => t.name === term.name);
      });
      this.selectedTerms = this.terms.map((term) => term.id);

      if (!this.terms.length) {
        this.$notify({
          type: 'error',
          text: this.$t('all-terms-already-exist'),
          duration: 15000,
        });
        this.selectedOntologyId = '';
        this.loading = false;
        return;
      }

      this.loading = false;
    },
    getTerm(termId) {
      return this.ontology.terms.find((term) => term.id === termId);
    },
    toggleAllTerms() {
      if (this.allTermsSelected) {
        this.selectedTerms = [];
      } else {
        this.selectedTerms = this.terms.map((term) => term.id);
      }
    },
    getValidationMessage() {
      switch (this.activeStep) {
        case 0:
          return this.$t('must-select-ontology');
        case 1:
          return this.$t('term-validation-message');
      }
    },

    async doSubmit() {
      try {
        await noteApi.post(
          `napi/ontology/${this.selectedOntologyId}/clone/terms`,
          {
            json: {
              targetOntologyId: this.ontology.id,
              terms: this.selectedTerms,
            },
          }
        );

        this.$emit('finished');
      } catch (error) {
        console.error(error);
        this.$notify({
          type: 'error',
          text: error?.message ?? this.$t('error-importing-terms'),
        });
      } finally {
        // reset modal state & hide modal
        for (const key in getDefaultState()) {
          this[key] = getDefaultState()[key];
        }
        this.$emit('update:active', false);
      }
    },
  },
};
</script>
<style scoped>
h4 {
  font-weight: bold;
}
>>> .animation-content {
  max-width: 60% !important;
  width: 60%;
}
>>> .modal-card {
  width: 100%;
  height: 80vh;
}
.image-overview {
  max-height: 4rem;
  max-width: 10rem;
}
.red {
  color: red;
}
</style>
<style>
.b-steps .step-content {
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: auto !important;
}
.b-steps .steps {
  width: 100%;
}
.b-steps .step-item {
  outline: none;
  box-shadow: none;
}
.b-steps .step-content > div {
  outline: none;
  box-shadow: none;
}
.step-images .b-radio.radio .control-label {
  padding-left: 0 !important;
}
label .label {
  margin-bottom: 0px;
}
label .label:not(:last-child) {
  margin-bottom: 0px;
}
</style>
